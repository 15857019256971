export default class SessionStatePersister {
  constructor() {
    if (this.constructor === SessionStatePersister) {
      throw new TypeError('Abstract class "SessionStatePersister" cannot be instantiated directly.');
    }
    this.namespace = 'default';
    this.enabled = true;
  }
  setNameSpace(namespace) {
    this.namespace = namespace;
  }
  enable(enable) {
    this.enabled = enable;
  }
  buildKey(subject) {
    return `${this.namespace}_${subject}`;
  }
  getPersistedValue(key, fallbackValue) {
    const builtKey = this.buildKey(key);
    const persistedTab =
      (this.enabled && sessionStorage.getItem(builtKey) && JSON.parse(sessionStorage.getItem(builtKey))) ||
      fallbackValue;
    return persistedTab;
  }
  persistValue(key, value) {
    if (!this.enabled) {
      return;
    }
    sessionStorage.setItem(this.buildKey(key), JSON.stringify(value));
  }
  getCleanupFunc(keys) {
    const builtKeys = keys.map(key => this.buildKey(key));
    return function() {
      builtKeys.forEach(builtKey => {
        sessionStorage.removeItem(builtKey);
      });
    };
  }
}
