<template>
  <crud-editor
    :id="restaurantID"
    ref="editor"
    :is-edit="isEdit"
    :definition="definition"
    :fetch-by-id="fetchById"
    :disabled="hasNoEditPermission"
    :return-to="returnTo"
    :item-type="entity"
    :page-loading="loading"
    :get-layout="layout"
  />
</template>

<script>
import CrudEditor from '@/components/crud/TabbedEditor';
import { entityToStore } from '@/utils/store';
import { hasEditPermissionForCode } from '@/utils/permission';
import { getEditLayoutDefinition, getFormDefinition, createFormDataFromItem } from './form';

export default {
  components: {
    CrudEditor
  },
  data() {
    return {
      isEdit: true,
      layout: getEditLayoutDefinition,
      entity: 'Restaurant',
      returnTo: 'Restaurants',
      restaurantCode: null
    };
  },
  computed: {
    restaurantID() {
      return this.$store.getters.restaurantId;
    },
    entityStore() {
      return entityToStore(this.entity);
    },
    hasNoEditPermission() {
      return !hasEditPermissionForCode(this.entity, this.$store.getters.permissions);
    },
    definition() {
      return this.createDefinition();
    },
    loading() {
      return this.$store.getters[this.entityStore + '/isLoading'];
    }
  },
  methods: {
    async fetchById(id) {
      const item = await this.$store.dispatch(this.entityStore + '/getItem', { selector: { id } });
      return this.createFormDataFromItem(this, item);
    },
    createDefinition() {
      return getFormDefinition(this);
    },
    createFormDataFromItem
  }
};
</script>
