<template>
  <div>
    <el-table :data="currentValue" fit style="width: 100%">
      <el-table-column v-for="dayOfWeek in daysOfWeek" :key="dayOfWeek.dayOfWeek" :label="dayOfWeek.name" :width="130">
        <template slot-scope="{ row }">
          <MDinput
            v-model="row.dailyOpeningHours[dayOfWeek.indexInArray].openingTime"
            :disabled="disabled"
            :maxlength="5"
            type="time"
            :placeholder="$t('restaurant.open')"
            @input="handleTimeChanged(row, $event)"
          />
          <MDinput
            v-model="row.dailyOpeningHours[dayOfWeek.indexInArray].closingTime"
            :disabled="disabled"
            :maxlength="5"
            type="time"
            :placeholder="$t('restaurant.closed')"
            @input="handleTimeChanged(row, $event)"
          />
          <div v-if="hasDailyOpeningHoursError(row, dayOfWeek.dayOfWeek)" class="validation-errors">
            {{ $t('validation.time') }}
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import MDinput from '@/components/MDinput';
import { DayOfWeek } from '@/utils/constants';
import { isTime } from '@/utils/validate';
export default {
  components: { MDinput },
  props: {
    value: {
      default() {
        return [];
      },
      type: [Array]
    },
    readonly: Boolean,
    disabled: Boolean
  },
  data() {
    return {
      currentValue: []
    };
  },
  computed: {
    daysOfWeek() {
      return [
        {
          dayOfWeek: DayOfWeek.Monday,
          name: this.$i18n.t('dayOfWeek.monday'),
          indexInArray: 1
        },
        {
          dayOfWeek: DayOfWeek.Tuesday,
          name: this.$i18n.t('dayOfWeek.tuesday'),
          indexInArray: 2
        },
        {
          dayOfWeek: DayOfWeek.Wednesday,
          name: this.$i18n.t('dayOfWeek.wednesday'),
          indexInArray: 3
        },
        {
          dayOfWeek: DayOfWeek.Thursday,
          name: this.$i18n.t('dayOfWeek.thursday'),
          indexInArray: 4
        },
        {
          dayOfWeek: DayOfWeek.Friday,
          name: this.$i18n.t('dayOfWeek.friday'),
          indexInArray: 5
        },
        {
          dayOfWeek: DayOfWeek.Saturday,
          name: this.$i18n.t('dayOfWeek.saturday'),
          indexInArray: 6
        },
        {
          dayOfWeek: DayOfWeek.Sunday,
          name: this.$i18n.t('dayOfWeek.sunday'),
          indexInArray: 0
        }
      ];
    }
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue;
    }
  },
  methods: {
    validate(row) {
      row.dailyOpeningHours.forEach(day => {
        const isValidTime = this.isValidTime(day.openingTime) && this.isValidTime(day.closingTime);
        this.$set(row, `dailyOpeningHoursError_${day.dayOfWeek}`, !isValidTime);
      });
    },
    isValidTime(time) {
      if (!time) {
        return true;
      }
      return isTime(time);
    },
    hasDailyOpeningHoursError(row, dayOfWeek) {
      const key = `dailyOpeningHoursError_${dayOfWeek}`;
      return row[key];
    },
    handleTimeChanged(row) {
      this.validate(row);
      this.$emit('input', this.value);
    }
  }
};
</script>
<style>
.el-table__body td {
  padding-top: 2px;
  padding-bottom: 4px;
}
.el-table th > .cell {
  word-break: break-word;
}
.el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #d3dce6;
}
.createPost-container .el-form-item label {
  margin-top: 0;
}
</style>
