import MDinput from '@/components/MDinput';
import TimePicker from '@/components/DSE/TimePicker';
import PostcodeRestaurantEditor from '../PostcodeRestaurantEditor';
import { DayOfWeek } from '@/utils/constants';
import OpeningHoursEditor from '../OpeningHoursEditor';

export function getFormDefinition(vue) {
  return {
    type: 'tab',
    saveButtons: [false, false], // Page, tab 1, tab 2, ...
    showFormLabels: [true, false], // Tab 1, tab 2, ....
    labels: ['restaurant.openingHours', 'restaurant.postcodes'],
    headerTemplate: (str, form) => `
    <h3>
    ${form.name || ''}
    </h3>
    `,
    groups: [
      // Tab Opening hours
      {
        openingTime: {
          type: TimePicker,
          defaultValue: null,
          props: {
            maxlength: 5,
            caption: 'restaurant.openingTime'
          }
        },
        closingTime: {
          type: TimePicker,
          defaultValue: null,
          props: {
            maxlength: 5,
            caption: 'restaurant.closingTime'
          }
        },
        dailyOpeningHoursForDelivery: {
          type: OpeningHoursEditor,
          defaultValue: null,
          props: {
            caption: 'restaurant.openingTimeForDelivery'
          }
        },
        dailyOpeningHoursForPickup: {
          type: OpeningHoursEditor,
          defaultValue: null,
          props: {
            caption: 'restaurant.openingTimeForPickup'
          }
        },
        openingTimesStatement: {
          type: MDinput,
          props: {
            maxlength: 255,
            caption: 'restaurant.openingTimesStatement'
          }
        },
        deliveryCostsLimit: {
          type: MDinput,
          defaultValue: 0,
          props: {
            type: 'currency',
            caption: 'restaurant.deliveryCostsLimit'
          }
        },
        minimumOrderValue: {
          type: MDinput,
          defaultValue: 0,
          props: {
            type: 'currency',
            caption: 'restaurant.minimumOrderValue'
          }
        }
      }, // Tab postcodes
      {
        postcodesOpeningHours: {
          type: PostcodeRestaurantEditor,
          props: {
            caption: 'common.emptyString',
            restaurantID: +vue.$route.params.id
          }
        }
      }
    ]
  };
}

const layoutFullWidth = {
  sections: [
    {
      width: 100
    }
  ]
};

export function getEditLayoutDefinition(vue) {
  return {
    groups: [layoutFullWidth, layoutFullWidth]
  };
}

export function createItemFromFormData(vue, form) {
  return {};
}

export function createFormDataFromItem(vue, item) {
  const form = {
    ...item,
    postcodesOpeningHours: item.postcodes
      .map(e => {
        return {
          ...e,
          dailyOpeningHours: mapOpeningHoursToForm(e.dailyOpeningHours)
        };
      })
      .sort((a, b) => {
        return +a.postcode < +b.postcode ? -1 : 1;
      }),
    dailyOpeningHoursForPickup: [{ dailyOpeningHours: mapOpeningHoursToForm(item.dailyOpeningHoursForPickup) }],
    dailyOpeningHoursForDelivery: [{ dailyOpeningHours: mapOpeningHoursToForm(item.dailyOpeningHoursForDelivery) }]
  };
  delete form.postcodes;
  return form;
}

function mapOpeningHoursToForm(dailyOpeningHours) {
  for (const [dayName, dayNumber] of Object.entries(DayOfWeek)) {
    const openingHours = dailyOpeningHours.find(e => e.dayOfWeek === dayNumber);
    if (!openingHours) {
      dailyOpeningHours.push({
        dayOfWeek: dayNumber,
        dayOfWeekName: dayName,
        openingTime: null,
        closingTime: null
      });
    } else {
      openingHours.dayOfWeekName = dayName;
    }
  }
  return dailyOpeningHours.sort((a, b) => {
    return a.dayOfWeek < b.dayOfWeek ? -1 : 1;
  });
}
