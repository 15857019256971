<template>
  <div class="createPost-container">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      class="form-container"
      :label-width="labelWidth"
      :label-position="formLabelPosition"
    >
      <sticky class-name="sub-navbar">
        <editor-button
          v-if="showDelete"
          style="margin-right:15px"
          :loading="actionLoading"
          action="delete"
          @click="handleDelete"
        />
        <slot name="page-buttons" v-bind="{ loading: actionLoading, pageSaveButton }" />
        <editor-button v-if="pageSaveButton && showSave" :loading="actionLoading" action="save" @click="handleSave" />
        <editor-button
          v-if="pageSaveButton && showSaveAndClose"
          :loading="actionLoading"
          action="saveClose"
          @click="saveAndClose"
        />
        <editor-button
          v-if="pageSaveButton && showSaveAndNext"
          :loading="actionLoading"
          action="saveNext"
          @click="saveAndNext"
        />
        <editor-button :loading="actionLoading" action="cancel" @click="close" />
      </sticky>
      <el-col v-if="headerTemplate" :xs="24" :md="12" :lg="8" :xl="6" class="createPost-header">
        <el-card v-loading="pageLoading">
          <div class="" v-html="headerTemplate"></div>
        </el-card>
      </el-col>
      <component
        :is="header.type || 'TabbedEditorHeaderCard'"
        v-if="header"
        class="createPost-header"
        :loading="pageLoading"
        v-bind="header.props"
      />
      <el-col>
        <div v-loading="pageLoading" class="createPost-tabs">
          <el-alert v-if="pageMessage" :title="pageMessage" type="info" :closable="false" />
          <el-tabs v-model="activeTabModel" type="border-card">
            <el-tab-pane
              v-for="(tabColumnsFields, tabIndex) in columnsFields"
              :key="tabIndex"
              :label="tabLabels[tabIndex]"
            >
              <sticky v-if="!!tabSaveButtons[tabIndex]" :class-name="'sub-navbar '">
                <editor-button v-if="!innerDisabled" :loading="actionLoading" action="save" @click="handleSave" />
                <editor-button
                  v-if="!innerDisabled"
                  :loading="actionLoading"
                  action="saveClose"
                  @click="saveAndClose"
                />
                <editor-button
                  v-if="enableSaveAndNext"
                  :loading="actionLoading"
                  action="saveNext"
                  @click="saveAndNext"
                />
              </sticky>
              <el-row :gutter="20">
                <el-col
                  v-for="(columnFields, columnIndex) in tabColumnsFields"
                  :key="columnIndex"
                  :xs="24"
                  :lg="getWidthFromPercentage(columnsWidthPercentages[tabIndex][columnIndex], tabColumnsFields)"
                >
                  <el-row
                    v-for="(spec, name, index) in columnFields"
                    v-show="getVisibleProp(spec) !== false"
                    :key="name"
                  >
                    <el-col v-if="isEdit || !spec.hideOnCreate">
                      <el-form-item
                        v-show="!spec.dynamicProps || spec.dynamicProps(form).visible !== false"
                        :prop="name"
                        :label="getLabel(spec, name)"
                      >
                        <template v-if="getInfoTooltip(spec)" v-slot:label>
                          {{ getLabel(spec, name) }}
                          <el-tooltip
                            class="infoToolTip"
                            effect="dark"
                            :content="getInfoTooltip(spec)"
                            placement="right"
                            icon="el-icon-info"
                          >
                            <span class="el-icon-info" />
                          </el-tooltip>
                        </template>
                        <component
                          :is="spec.type"
                          :ref="createRef(tabIndex, columnIndex, index)"
                          v-model="form[name]"
                          v-bind="getProps(spec)"
                          :disabled="getDisabledProp(spec)"
                          :name="name"
                          :initial-value="formData[name]"
                          :editor-number="editorNumber"
                          @activatetab="handleActivateTab"
                          @cleaner="registerCleaner(name, $event)"
                          @presave="registerPreSaveHook(name, $event)"
                          @postsave="registerPostSaveHook(name, $event)"
                          @requesterror="registerRequestErrorHook(name, $event)"
                          @init="
                            initialVal => {
                              handleInit(name, initialVal);
                            }
                          "
                          @input="clearInputErrorFor(name, spec.showErrorsFromFields)"
                        >
                        </component>
                        <input-errors :errors="getInputErrors(errors, name, spec, form)" />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
    </el-form>
  </div>
</template>

<script>
import Sticky from '@/components/Sticky'; // Sticky header-component
import editorMixin from './mixins/editor-mixin';
import EditorButton from './EditorButton';
import InputErrors from '@/components/DSE/InputErrors';
import TabbedEditorHeaderCard from '@/components/crud/TabbedEditorHeaderCard';
import TabbedEditorPersister from './TabbedEditorPersister';

export default {
  components: {
    Sticky,
    EditorButton,
    InputErrors,
    TabbedEditorHeaderCard
  },
  mixins: [editorMixin],
  props: {
    persistState: {
      type: Boolean,
      default: true
    }
  },
  data() {
    const definitionGroups = this.definition.groups;
    const fieldsDefinition = [];
    const fieldNames = [];
    let defaultForm = {};
    definitionGroups.forEach(function(definition, index) {
      fieldsDefinition[index] = definition;
      fieldNames[index] = Object.keys(fieldsDefinition[index]);
      defaultForm = Object.assign(
        {},
        fieldNames[index].reduce((form, name) => {
          form[name] = fieldsDefinition[index][name].defaultValue;
          return form;
        }, {})
      );
    });

    return {
      header: {},
      headerTemplate: '',
      form: defaultForm,
      activeTabModel: '0'
    };
  },
  computed: {
    labelWidth() {
      const showFormLabels = this.definition.showFormLabels || [];
      return showFormLabels[+this.activeTabModel] ? '150px' : '0px';
    },
    rules() {
      const fields = this.fields.reduce((fields, tabFields) => ({ ...fields, ...tabFields }));
      return this.getRules(fields);
    },
    tabLabels() {
      const labels = this.definition.labels;
      return labels.map(e => {
        if (typeof e === 'string') {
          return this.$t(e);
        } else {
          // object
          return this.$t(e.string, { count: this.$store.getters[e.countStoreGetter] });
        }
      });
    },
    tabNames() {
      return this.definition.names || [];
    },
    pageSaveButton() {
      return !this.innerDisabled && (this.definition.saveButtons ? !!this.definition.saveButtons[0] : true);
    },
    tabSaveButtons() {
      return !this.innerDisabled && (this.definition.saveButtons ? this.definition.saveButtons.slice(1) : []);
    },
    definitionsObject() {
      return this.definition.groups.reduce((acc, group) => {
        acc = { ...acc, ...group };
        return acc;
      }, {});
    },
    fields() {
      const fieldsDefinition = this.definition.groups;
      const fieldNames = [];
      fieldsDefinition.forEach(function(definition, index) {
        fieldNames[index] = Object.keys(definition);
      });

      const fields = [];
      fieldNames.forEach(function(fieldName, index) {
        const target = {};
        fieldName.reduce((target, name) => {
          const field = fieldsDefinition[index][name];
          if (field.type) {
            target[name] = field;
          }
          return target;
        }, target);
        fields[index] = target;
      });
      return fields;
    },
    columnsFields() {
      const tabColumnsFields = [];

      if (this.getLayout) {
        const tabLayouts = this.getLayout(this).groups;
        tabLayouts.forEach((tabFields, tabIndex) => {
          const layout = tabLayouts[tabIndex];
          const columnsProps = this.generateColumnsPropertiesFromPageLayout(layout, tabIndex);
          tabColumnsFields.push(columnsProps.columnFields);
        });
      } else {
        this.fields.forEach((tabFields, tabIndex) => {
          tabColumnsFields.push(this.splitFieldsInHalfAsArray(tabFields));
        });
      }
      return tabColumnsFields;
    },
    columnsWidthPercentages() {
      const tabColumnsWidths = [];
      if (this.getLayout) {
        const tabLayouts = this.getLayout(this).groups;
        tabLayouts.forEach((tabFields, tabIndex) => {
          const layout = tabLayouts[tabIndex];
          const columnsProps = this.generateColumnsPropertiesFromPageLayout(layout);
          tabColumnsWidths.push(columnsProps.widths);
        });
      } else {
        this.fields.forEach((tabFields, tabIndex) => {
          tabColumnsWidths.push([]);
        });
      }
      return tabColumnsWidths;
    }
  },
  watch: {
    form: {
      deep: true,
      handler: function(form) {
        this.headerTemplate = this.definition.headerTemplate && this.definition.headerTemplate`${form}`;
        this.header = this.definition.header && this.definition.header(form);
      }
    },
    activeTabModel(activeTabModel) {
      this.persistTab(activeTabModel);
    }
  },
  beforeCreate() {
    const { itemType, persistState } = this.$options.propsData;
    const entityId = (this.$route && this.$route.params && this.$route.params.id) || '';
    const userId = this.$store.state.authentication.userId;

    this.persistentState = new TabbedEditorPersister();
    this.persistentState.setNameSpace(`${itemType}_${entityId}_${userId}`);
    if (persistState !== undefined) {
      this.persistentState.enable(persistState);
    } else {
      this.persistentState.enable(true);
    }
  },
  mounted() {
    const persistedTab = this.persistentState.getPersistedTab();
    const routeQueryTab = this.$route.query && this.$route.query.tab;
    if (persistedTab) {
      this.handleActivateTab(persistedTab);
    } else if (routeQueryTab) {
      this.handleActivateTab(routeQueryTab);
    } else if (this.definition.defaultTab) {
      this.handleActivateTab(this.definition.defaultTab(this.isEdit) || 0);
    }

    this.$store.dispatch('registerOnCloseView', { view: this.$route, hook: this.persistentState.getCleanupFunc() });
  },
  methods: {
    persistTab(tab) {
      this.persistentState.persistTab(tab);
    },
    handleActivateTab(newActiveTab) {
      let tabIndex = this.tabNames.indexOf(newActiveTab);
      if (tabIndex === -1) {
        tabIndex = +newActiveTab;
      }
      if (tabIndex >= 0) {
        this.activeTabModel = tabIndex + '';
      }
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import '~@/styles/mixin.scss';

.createPost-container {
  position: relative;

  .createPost-main-container {
    padding: 40px 45px 20px 50px;

    .postInfo-container {
      position: relative;
      @include clearfix;
      margin-bottom: 10px;

      .postInfo-container-item {
        float: left;
      }
    }
  }
}

.createPost-tabs {
  margin: 10px;
  border-radius: 0;
  .el-tab-pane {
    padding: 10px 25px 20px 20px;
  }
}
.createPost-header {
  word-wrap: break-word;
}
</style>
<style lang="scss">
.createPost-header {
  padding: 10px;
  h3 {
    margin-block-start: 0;
    margin-block-end: 0;
  }
}
</style>
