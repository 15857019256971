var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"tableContainer"},[_c('el-row',{staticClass:"page-buttons"},[_c('div',{staticClass:"buttons-left"},[_c('editor-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.notDisabled),expression:"notDisabled"}],attrs:{"action":"add"},on:{"click":_vm.addRow}})],1)]),_c('el-table',{ref:"postcodeRestaurantList",staticStyle:{"width":"100%"},attrs:{"data":_vm.currentValue,"border":"","fit":"","highlight-current-row":"","max-height":_vm.getMaxTableHeight(),"stripe":""},on:{"selection-change":_vm.handleSelectionChange}},[(_vm.notDisabled)?_c('el-table-column',{attrs:{"type":"selection","width":"55"}}):_vm._e(),_c('el-table-column',{attrs:{"label-class-name":"filter-cell"},scopedSlots:_vm._u([{key:"header",fn:function(ref){return undefined}}])},[_c('el-table-column',{attrs:{"label":_vm.$t('common.postalCode'),"width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('MDinput',{attrs:{"disabled":_vm.disabled,"max-length":6,"type":"number","precision":0,"readonly":row.postcodeRestaurantID > 0},on:{"input":function($event){return _vm.handlePostcodeChange(row, $event)}},model:{value:(row.postcode),callback:function ($$v) {_vm.$set(row, "postcode", $$v)},expression:"row.postcode"}}),(row.postcodeUniquenessViolation)?_c('div',{staticClass:"validation-errors"},[_vm._v(" "+_vm._s(_vm.$t('restaurant.postcodeUniquenessViolation', { alreadyUsedBy: row.postcodeUniquenessViolation }))+" ")]):_vm._e(),(row.postcodeFormatError)?_c('div',{staticClass:"validation-errors"},[_vm._v(" "+_vm._s(_vm.$t('validation.postcode'))+" ")]):_vm._e()]}}])})],1),_c('el-table-column',{attrs:{"label-class-name":"filter-cell"},scopedSlots:_vm._u([{key:"header",fn:function(ref){return undefined}}])},[_c('el-table-column',{attrs:{"label":_vm.$t('restaurant.deliveryCharge'),"width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('MDinput',{attrs:{"type":"currency","disabled":_vm.disabled},on:{"input":function($event){return _vm.handleDeliveryChargesChange(row)}},model:{value:(row.deliveryCharge),callback:function ($$v) {_vm.$set(row, "deliveryCharge", $$v)},expression:"row.deliveryCharge"}}),(row.deliveryChargeError)?_c('div',{staticClass:"validation-errors"},[_vm._v(" "+_vm._s(_vm.$t('validation.required'))+" ")]):_vm._e()]}}])})],1),_c('el-table-column',{attrs:{"label-class-name":"filter-cell"},scopedSlots:_vm._u([{key:"header",fn:function(ref){return undefined}}])},[_c('el-table-column',{attrs:{"label":_vm.$t('restaurant.freeDeliveryThreshold'),"width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('MDinput',{attrs:{"type":"currency","disabled":_vm.disabled},on:{"input":function($event){return _vm.handleFreeDeliveryThresholdChange(row)}},model:{value:(row.freeDeliveryThreshold),callback:function ($$v) {_vm.$set(row, "freeDeliveryThreshold", $$v)},expression:"row.freeDeliveryThreshold"}}),(row.freeDeliveryThresholdError)?_c('div',{staticClass:"validation-errors"},[_vm._v(" "+_vm._s(_vm.$t('validation.required'))+" ")]):_vm._e()]}}])})],1),_c('el-table-column',{attrs:{"label-class-name":"filter-cell"},scopedSlots:_vm._u([{key:"header",fn:function(ref){return undefined}}])},[_c('el-table-column',{attrs:{"label":_vm.$t('restaurant.minimumOrderValue'),"width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('MDinput',{attrs:{"type":"currency","disabled":_vm.disabled},on:{"input":function($event){return _vm.handleMinimumOrderValueChange(row)}},model:{value:(row.minimumOrderValue),callback:function ($$v) {_vm.$set(row, "minimumOrderValue", $$v)},expression:"row.minimumOrderValue"}}),(row.minimumOrderValueError)?_c('div',{staticClass:"validation-errors"},[_vm._v(" "+_vm._s(_vm.$t('validation.required'))+" ")]):_vm._e()]}}])})],1),_vm._l((_vm.daysOfWeek),function(dayOfWeek){return _c('el-table-column',{key:dayOfWeek.dayOfWeek,attrs:{"label-class-name":"filter-cell"},scopedSlots:_vm._u([{key:"header",fn:function(ref){return undefined}}],null,true)},[_c('el-table-column',{attrs:{"prop":'dayOfWeek_' + dayOfWeek.dayOfWeek,"label":dayOfWeek.name,"width":130},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('MDinput',{attrs:{"maxlength":5,"disabled":_vm.disabled,"type":"time","placeholder":_vm.$t('restaurant.open')},on:{"input":function($event){return _vm.handleOpeningTimeChanged(row, dayOfWeek.indexInArray)}},model:{value:(row.dailyOpeningHours[dayOfWeek.indexInArray].openingTime),callback:function ($$v) {_vm.$set(row.dailyOpeningHours[dayOfWeek.indexInArray], "openingTime", $$v)},expression:"row.dailyOpeningHours[dayOfWeek.indexInArray].openingTime"}}),_c('MDinput',{attrs:{"disabled":_vm.disabled,"maxlength":5,"type":"time","placeholder":_vm.$t('restaurant.closed')},on:{"input":function($event){return _vm.handleClosingTimeChanged(row, dayOfWeek.indexInArray)}},model:{value:(row.dailyOpeningHours[dayOfWeek.indexInArray].closingTime),callback:function ($$v) {_vm.$set(row.dailyOpeningHours[dayOfWeek.indexInArray], "closingTime", $$v)},expression:"row.dailyOpeningHours[dayOfWeek.indexInArray].closingTime"}}),(_vm.hasDailyOpeningHoursError(row, dayOfWeek.dayOfWeek))?_c('div',{staticClass:"validation-errors"},[_vm._v(" "+_vm._s(_vm.$t('validation.time'))+" ")]):_vm._e()]}}],null,true)})],1)})],2),_c('div',{staticStyle:{"margin-top":"2px"}},[_c('editor-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.notDisabled),expression:"notDisabled"}],attrs:{"action":"add"},on:{"click":_vm.addRow}}),(_vm.multipleSelection.length > 0)?_c('el-button',{on:{"click":function($event){return _vm.deleteSelection()}}},[_vm._v(" "+_vm._s(_vm.$t('common.deleteSelectedItems'))+" ")]):_vm._e(),(_vm.multipleSelection.length > 0)?_c('el-button',{on:{"click":function($event){return _vm.clearSelection()}}},[_vm._v(" "+_vm._s(_vm.$t('common.clearSelection'))+" ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }