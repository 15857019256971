export const CouponType = {
  DiscountPercentage: 0,
  DiscountAmount: 1,
  FreeProduct: 2
};

export const ScratchAndWinPriceType = {
  CouponForNextOrder: 0,
  GrandPrize: 1
};

export const MessageType = {
  SMS: 0,
  Mail: 1,
  WhatsApp: 2
};

export const TransactionType = {
  Add: 0,
  Substract: 1
};

export const VATRateOption = {
  Zero: 0,
  Reduced: 1,
  Standard: 2
};

export const PaymentMethodOption = {
  Cash: 0,
  Online: 1,
  Pin: 2,
  Internet: 3,
  Account: 4,
  Ideal: 5,
  Other: 6,
  Sofort: 7,
  Giropay: 8,
  Unknown: 9,
  Eckarte: 10,
  PinAtDoorstep: 11,
  CreditcardAtDoorstep: 12,
  Giftcard: 13
};

export const DeliveryMethodOption = {
  Delivery: 0,
  Pickup: 1
};

export const RibbonTypeOption = {
  None: 0,
  Primary: 1,
  Secondary: 2,
  Tertiary: 3
};

export const BlockTypeOption = {
  Specials1: 0,
  Specials2: 1,
  Specials3: 2,
  Storytelling1: 3,
  Storytelling2: 4,
  Storytelling3: 5,
  StorytellingBackgroundImages: 6,
  StorytellingVideo: 7,
  MenuCategories: 8,
  UniqueSellingPoints: 9,
  SpecialsIntro: 10,
  SpecialsApp1: 11,
  SpecialsApp2: 12,
  SpecialsApp3: 13,
  SpecialsLocal: 14,
  SpecialsAppLocal: 15
};

export const DayOfWeek = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6
};

export const DeviceType = {
  ECR: 0,
  Mobile: 1
};

export const PostcodeLength = {
  DutchPostcodeLength: 4,
  GermanPostcodeLength: 5,
  DutchFullPostcodeLength: 6
};

export const ScratchAndWinBatchState = {
  NotGenerated: 0,
  Generating: 1,
  Generated: 2
};

export const MenuBlockType = {
  Taste: 0,
  Topping: 1,
  Sauce: 2,
  SideDish: 3,
  MoreMainProduct: 4
};

export const getMenuBlockTypeOptions = vue => {
  return [
    {
      code: MenuBlockType.Taste,
      description: vue.$i18n.t('menu.menuBlockTypes.taste')
    },
    {
      code: MenuBlockType.Topping,
      description: vue.$i18n.t('menu.menuBlockTypes.topping')
    },
    {
      code: MenuBlockType.Sauce,
      description: vue.$i18n.t('menu.menuBlockTypes.sauce')
    },
    {
      code: MenuBlockType.SideDish,
      description: vue.$i18n.t('menu.menuBlockTypes.sideDish')
    },
    {
      code: MenuBlockType.MoreMainProduct,
      description: vue.$i18n.t('menu.menuBlockTypes.moreMainProduct')
    }
  ];
};
