<template>
  <div ref="tableContainer">
    <el-row class="page-buttons">
      <div class="buttons-left">
        <editor-button v-show="notDisabled" action="add" @click="addRow" />
      </div>
    </el-row>
    <el-table
      ref="postcodeRestaurantList"
      :data="currentValue"
      border
      fit
      highlight-current-row
      style="width: 100%"
      :max-height="getMaxTableHeight()"
      stripe
      @selection-change="handleSelectionChange"
    >
      <el-table-column v-if="notDisabled" type="selection" width="55"></el-table-column>
      <el-table-column label-class-name="filter-cell">
        <template slot="header" slot-scope="{}"></template>
        <el-table-column :label="$t('common.postalCode')" width="100">
          <template slot-scope="{ row }">
            <MDinput
              v-model="row.postcode"
              :disabled="disabled"
              :max-length="6"
              type="number"
              :precision="0"
              :readonly="row.postcodeRestaurantID > 0"
              @input="handlePostcodeChange(row, $event)"
            />
            <div v-if="row.postcodeUniquenessViolation" class="validation-errors">
              {{ $t('restaurant.postcodeUniquenessViolation', { alreadyUsedBy: row.postcodeUniquenessViolation }) }}
            </div>
            <div v-if="row.postcodeFormatError" class="validation-errors">
              {{ $t('validation.postcode') }}
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label-class-name="filter-cell">
        <template slot="header" slot-scope="{}"></template>
        <el-table-column :label="$t('restaurant.deliveryCharge')" width="120">
          <template slot-scope="{ row }">
            <MDinput
              v-model="row.deliveryCharge"
              type="currency"
              :disabled="disabled"
              @input="handleDeliveryChargesChange(row)"
            />
            <div v-if="row.deliveryChargeError" class="validation-errors">
              {{ $t('validation.required') }}
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label-class-name="filter-cell">
        <template slot="header" slot-scope="{}"></template>
        <el-table-column :label="$t('restaurant.freeDeliveryThreshold')" width="150">
          <template slot-scope="{ row }">
            <MDinput
              v-model="row.freeDeliveryThreshold"
              type="currency"
              :disabled="disabled"
              @input="handleFreeDeliveryThresholdChange(row)"
            />
            <div v-if="row.freeDeliveryThresholdError" class="validation-errors">
              {{ $t('validation.required') }}
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label-class-name="filter-cell">
        <template slot="header" slot-scope="{}"></template>
        <el-table-column :label="$t('restaurant.minimumOrderValue')" width="150">
          <template slot-scope="{ row }">
            <MDinput
              v-model="row.minimumOrderValue"
              type="currency"
              :disabled="disabled"
              @input="handleMinimumOrderValueChange(row)"
            />
            <div v-if="row.minimumOrderValueError" class="validation-errors">
              {{ $t('validation.required') }}
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column v-for="dayOfWeek in daysOfWeek" :key="dayOfWeek.dayOfWeek" label-class-name="filter-cell">
        <template slot="header" slot-scope="{}"></template>
        <el-table-column :prop="'dayOfWeek_' + dayOfWeek.dayOfWeek" :label="dayOfWeek.name" :width="130">
          <template slot-scope="{ row }">
            <MDinput
              v-model="row.dailyOpeningHours[dayOfWeek.indexInArray].openingTime"
              :maxlength="5"
              :disabled="disabled"
              type="time"
              :placeholder="$t('restaurant.open')"
              @input="handleOpeningTimeChanged(row, dayOfWeek.indexInArray)"
            />
            <MDinput
              v-model="row.dailyOpeningHours[dayOfWeek.indexInArray].closingTime"
              :disabled="disabled"
              :maxlength="5"
              type="time"
              :placeholder="$t('restaurant.closed')"
              @input="handleClosingTimeChanged(row, dayOfWeek.indexInArray)"
            />
            <div v-if="hasDailyOpeningHoursError(row, dayOfWeek.dayOfWeek)" class="validation-errors">
              {{ $t('validation.time') }}
            </div>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
    <div style="margin-top: 2px">
      <editor-button v-show="notDisabled" action="add" @click="addRow" />
      <el-button v-if="multipleSelection.length > 0" @click="deleteSelection()">
        {{ $t('common.deleteSelectedItems') }}
      </el-button>
      <el-button v-if="multipleSelection.length > 0" @click="clearSelection()">
        {{ $t('common.clearSelection') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import MDinput from '@/components/MDinput';
import { DayOfWeek, PostcodeLength } from '@/utils/constants';
import { isTime } from '@/utils/validate';
import EditorButton from '@/components/crud/EditorButton';

export default {
  components: { MDinput, EditorButton },
  props: {
    value: {
      default() {
        return [];
      },
      type: [Array]
    },
    readonly: Boolean,
    disabled: Boolean,
    restaurantID: Number,
    countryCode: String
  },
  data() {
    return {
      currentValue: [],
      multipleSelection: []
    };
  },
  computed: {
    notDisabled() {
      return !this.disabled;
    },
    daysOfWeek() {
      return [
        {
          dayOfWeek: DayOfWeek.Monday,
          name: this.$i18n.t('dayOfWeek.monday'),
          indexInArray: 1
        },
        {
          dayOfWeek: DayOfWeek.Tuesday,
          name: this.$i18n.t('dayOfWeek.tuesday'),
          indexInArray: 2
        },
        {
          dayOfWeek: DayOfWeek.Wednesday,
          name: this.$i18n.t('dayOfWeek.wednesday'),
          indexInArray: 3
        },
        {
          dayOfWeek: DayOfWeek.Thursday,
          name: this.$i18n.t('dayOfWeek.thursday'),
          indexInArray: 4
        },
        {
          dayOfWeek: DayOfWeek.Friday,
          name: this.$i18n.t('dayOfWeek.friday'),
          indexInArray: 5
        },
        {
          dayOfWeek: DayOfWeek.Saturday,
          name: this.$i18n.t('dayOfWeek.saturday'),
          indexInArray: 6
        },
        {
          dayOfWeek: DayOfWeek.Sunday,
          name: this.$i18n.t('dayOfWeek.sunday'),
          indexInArray: 0
        }
      ];
    }
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue;
    }
  },
  methods: {
    async handlePostcodeChange(row, postcode) {
      if (`${postcode}`.length >= PostcodeLength.DutchPostcodeLength) {
        const restaurant = await this.$store.dispatch('postcode/getRestaurantByPostcode', {
          postcode: postcode,
          countryCode: this.countryCode
        });
        const usageCount = this.value.filter(e => +e.postcode === +postcode).length;
        let postcodeUniquenessViolation =
          restaurant && restaurant.restaurantID !== this.restaurantID && restaurant.name;
        if (usageCount > 1) {
          postcodeUniquenessViolation = this.$t('validation.unique');
        }
        this.$set(row, 'postcodeUniquenessViolation', postcodeUniquenessViolation);
      } else {
        this.$set(row, 'postcodeUniquenessViolation', null);
      }
      this.validate(row);
      this.$emit('input', this.value);
    },
    validate(row) {
      this.$set(row, 'postcodeFormatError', `${row.postcode}`.length < PostcodeLength.DutchPostcodeLength);
      this.$set(row, 'deliveryChargeError', !this.isValidAmount(row.deliveryCharge));
      this.$set(row, 'minimumOrderValueError', !this.isValidAmount(row.minimumOrderValue));
      this.$set(row, 'freeDeliveryThresholdError', !this.isValidAmount(row.freeDeliveryThreshold));
      row.dailyOpeningHours.forEach(day => {
        const isValidTime = this.isValidTime(day.openingTime) && this.isValidTime(day.closingTime);
        this.$set(row, `dailyOpeningHoursError_${day.dayOfWeek}`, !isValidTime);
      });
    },
    isValidAmount(value) {
      return !((value !== 0 && !value) || value.length === 0);
    },
    isValidTime(time) {
      if (!time) {
        return true;
      }
      return isTime(time);
    },
    hasDailyOpeningHoursError(row, dayOfWeek) {
      const key = `dailyOpeningHoursError_${dayOfWeek}`;
      return row[key];
    },
    handleDeliveryChargesChange(row) {
      this.validate(row);
      this.multipleRowChange(row, 'deliveryCharge', row.deliveryCharge);
      this.$emit('input', this.value);
    },
    handleFreeDeliveryThresholdChange(row) {
      this.validate(row);
      this.multipleRowChange(row, 'freeDeliveryThreshold', row.freeDeliveryThreshold);
      this.$emit('input', this.value);
    },
    handleMinimumOrderValueChange(row) {
      this.validate(row);
      this.multipleRowChange(row, 'minimumOrderValue', row.minimumOrderValue);
      this.$emit('input', this.value);
    },
    handleOpeningTimeChanged(row, dayOfWeekIndex) {
      this.validate(row);
      this.multipleRowChangeOpeningHours(
        row,
        dayOfWeekIndex,
        'openingTime',
        row.dailyOpeningHours[dayOfWeekIndex].openingTime
      );
      this.$emit('input', this.value);
    },
    handleClosingTimeChanged(row, dayOfWeekIndex) {
      this.validate(row);
      this.multipleRowChangeOpeningHours(
        row,
        dayOfWeekIndex,
        'closingTime',
        row.dailyOpeningHours[dayOfWeekIndex].closingTime
      );
      this.$emit('input', this.value);
    },
    handleSelectionChange(selectedRows) {
      this.multipleSelection = selectedRows;
    },
    clearSelection() {
      this.$refs.postcodeRestaurantList.clearSelection();
    },
    deleteSelection() {
      const newValue = this.value.filter(row => !this.multipleSelection.includes(row));
      this.$emit('input', newValue);
    },
    addRow() {
      const newValue = this.value;
      const newRow = {
        postcode: null,
        deliveryCharge: 0,
        freeDeliveryThreshold: 0,
        minimumOrderValue: 0,
        dailyOpeningHours: []
      };
      for (const [dayName, dayNumber] of Object.entries(DayOfWeek)) {
        newRow.dailyOpeningHours.push({
          dayOfWeek: dayNumber,
          dayOfWeekName: dayName,
          openingTime: null,
          closingTime: null
        });
      }
      newValue.push(newRow);
      this.$emit('input', newValue);
      this.$refs.postcodeRestaurantList.setCurrentRow(newRow);

      setTimeout(function() {
        const currentRow = document.querySelector('.current-row');
        if (currentRow) {
          currentRow.scrollIntoView();
          const postcodeInput = currentRow.querySelector('.material-input');
          postcodeInput.focus();
        }
      });
    },
    getMaxTableHeight() {
      if (this.$refs.tableContainer) {
        return window.innerHeight - this.$refs.tableContainer.getBoundingClientRect().top - 200;
      }
    },
    multipleRowChange(row, key, value) {
      if (this.multipleSelection.some(e => e.postcodeRestaurantID === row.postcodeRestaurantID)) {
        this.value
          .filter(
            e =>
              e.postcodeRestaurantID !== row.postcodeRestaurantID &&
              this.multipleSelection.some(item => item.postcodeRestaurantID === e.postcodeRestaurantID)
          )
          .forEach(e => {
            this.$set(e, key, value);
            this.validate(e);
          });
      }
    },
    multipleRowChangeOpeningHours(row, dayOfWeekIndex, key, value) {
      if (this.multipleSelection.some(e => e.postcodeRestaurantID === row.postcodeRestaurantID)) {
        this.value
          .filter(
            e =>
              e.postcodeRestaurantID !== row.postcodeRestaurantID &&
              this.multipleSelection.some(item => item.postcodeRestaurantID === e.postcodeRestaurantID)
          )
          .forEach(e => {
            this.$set(e.dailyOpeningHours[dayOfWeekIndex], key, value);
            this.validate(e);
          });
      }
    }
  }
};
</script>
<style>
.el-table__body td {
  padding-top: 2px;
  padding-bottom: 4px;
}

.el-table th > .cell {
  word-break: break-word;
}

.el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #d3dce6;
}

.createPost-container .el-form-item label {
  margin-top: 0;
}
</style>
