<template>
  <div>
    <span class="material-input-bar" />
    <label class="material-label">
      <slot />
    </label>
    <br v-if="$slots['default']" />
    <div class="timePicker">
      <el-time-picker
        v-model="currentValue"
        :value-format="timeFormat"
        :readonly="readonly"
        :disabled="disabled"
        :required="required"
        :placeholder="fillPlaceHolder"
        :picker-options="Object.assign(defaultPickerOptions, pickerOptions)"
        @input="handleModelInput"
      >
      </el-time-picker>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    name: String,
    value: [String, Number],
    placeholder: {
      type: String,
      default: ''
    },
    readonly: Boolean,
    disabled: Boolean,
    required: {
      type: Boolean,
      default: true
    },
    pickerOptions: Object
  },
  data() {
    return {
      currentValue: this.value,
      defaultPickerOptions: {
        selectableRange: '0:00:00 - 23:59:59',
        format: this.timeFormat
      }
    };
  },
  computed: {
    timeFormat() {
      return this.$store.getters['timeFormat'];
    },
    fillPlaceHolder() {
      return this.currentValue ? '' : this.placeholder;
    }
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue;
    }
  },
  methods: {
    handleModelInput(event) {
      this.$emit('input', event);
      this.$emit('change', event);
    }
  }
};
</script>
